import React from 'react'
import moment from 'moment'

import ContainedButton from 'components/buttons/contained-button'
import Input, { InputGroup } from 'components/input'
import Textarea from 'components/textarea'
import Dropdown from 'components/dropdown'
import YesNoDropdown from 'components/dropdown/yesno-dropdown'

export default ({ formData, errors, onChange, onSubmit, onBack }) => {
  return (
    <form onChange={onChange} onSubmit={onSubmit}>
      <InputGroup>
        <div>
          <Input
            label="Company"
            name="company"
            type="text"
            error={errors.company}
            defaultValue={formData.company}
            required
          />
          <Dropdown
            label="What is your role?"
            prompt={'Select your role in the company'}
            name="roleInCompany"
            error={errors.roleInCompany}
            defaultValue={formData.roleInCompany}
            required
          >
            <option value={'owner'}>Owner</option>
            <option value={'employee'}>Employee</option>
          </Dropdown>
          <Input
            label="Business Type/Decription"
            name="businessDescription"
            type="text"
            error={errors.businessDescription}
            defaultValue={formData.businessDescription}
            required
          />
          <Input
            label="Position"
            name="position"
            type="text"
            error={errors.position}
            defaultValue={formData.position}
            required
          />
          <Input
            label="Annual Income"
            name="annualIncome"
            type="text"
            error={errors.annualIncome}
            defaultValue={formData.annualIncome}
            required
          />
          <InputGroup>
            <div className="flex flex-row space-x-4">
              <Input
                label="Start Date"
                name="startDate"
                type="date"
                max={moment().format('YYYY-MM-DD')}
                error={errors.startDate}
                defaultValue={formData.startDate}
                required
              />
              <Dropdown
                label="Are you currently employed here?"
                prompt={'Select answer'}
                name="currentlyEmployed"
                error={errors.currentlyEmployed}
                defaultValue={formData.currentlyEmployed}
                required
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </Dropdown>
              {formData.currentlyEmployed === false && (
                <Input
                  label="End Date"
                  name="endDate"
                  type="date"
                  error={errors.endDate}
                  defaultValue={formData.endDate}
                  required
                />
              )}
            </div>
          </InputGroup>
        </div>
      </InputGroup>

      <YesNoDropdown
        label="Have you ever owned your own business?"
        prompt={'Select answer'}
        name="ownBusiness"
        error={errors.ownBusiness}
        defaultValue={formData.ownBusiness}
        required
      />

      <YesNoDropdown
        label="Have you ever owned an educational business?"
        prompt={'Select answer'}
        name="ownEducationalBusiness"
        error={errors.ownEducationalBusiness}
        defaultValue={formData.ownEducationalBusiness}
        required
      />

      {formData.ownEducationalBusiness && (
        <Textarea
          label="If you answered yes the question above, list the name and brief description of the business(es), an organization chart, and the type of curriculum used:"
          name="educationalBusinessDescription"
          error={errors.educationalBusinessDescription}
          defaultValue={formData.educationalBusinessDescription}
          required
        />
      )}

      <Dropdown
        label="Are you currently insured?"
        prompt={'Select answer'}
        name="areInsured"
        error={errors.areInsured}
        defaultValue={formData.areInsured}
        required
      >
        <option value={true}>Yes</option>
        <option value={false}>No</option>
      </Dropdown>

      <Dropdown
        label="Have you ever been denied insurance for your business(es)?"
        prompt={'Select answer'}
        name="everDeniedInsurance"
        error={errors.everDeniedInsurance}
        defaultValue={formData.everDeniedInsurance}
        required
      >
        <option value={true}>Yes</option>
        <option value={false}>No</option>
      </Dropdown>

      {formData.everDeniedInsurance && (
        <Input
          label="If yes, please explain:"
          name="deniedInsuranceReason"
          type="text"
          error={errors.deniedInsuranceReason}
          defaultValue={formData.deniedInsuranceReason}
          required
        />
      )}

      <div className="flex flex-row justify-between">
        <ContainedButton onClick={onBack}>Back</ContainedButton>
        <ContainedButton type="submit">Continue</ContainedButton>
      </div>
    </form>
  )
}
