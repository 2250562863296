import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'

import ApplicationLayout from 'components/layouts/preliminary-application-layout'
import { usePreliminaryApplication } from 'contexts/preliminary-application-context'
import BusinessForm from 'components/pages/preliminary-application/business-experience-form'

export default ({ location }) => {
  const [formData, setFormData] = useState(undefined)
  const [errors, setErrors] = useState(undefined)
  const application = usePreliminaryApplication()

  useEffect(() => {
    if (application.initialized) {
      application.setStep('businessExperience')
      setFormData({ ...application.businessExperience.fields })
      setErrors({ ...application.businessExperience.errors })
    }
  }, [application.initialized])

  const handleFormChange = event => {
    const { name, value } = event.target

    switch (name) {
      case 'currentlyEmployed':
      case 'ownBusiness':
      case 'ownEducationalBusiness':
      case 'areInsured':
      case 'everDeniedInsurance':
      case 'stillEmployed':
        setFormData({
          ...formData,
          [name]:
            value === 'true' ? true : value === 'false' ? false : undefined,
        })
        break
      default:
        setFormData({ ...formData, [name]: value })
    }
  }

  const handleGoBack = event => {
    event.preventDefault()
    application.goPreviousStep()
    navigate('/preliminary-application/personal-information')
  }

  const handleFormSubmit = async event => {
    event.preventDefault()
    console.log('handleFormSubmit')

    try {
      // TODO:
      await application.update('businessExperience', formData)
      navigate('/preliminary-application/educational-background')
    } catch (error) {
      // TODO:
    }
  }

  console.log('form', formData)

  if (!application.initialized || !formData) {
    return <div>Loading...</div>
  }

  return (
    <ApplicationLayout
      step="Business Experience"
      stepNumber={2}
      location={location}
    >
      <BusinessForm
        formData={formData}
        errors={errors}
        onChange={handleFormChange}
        onSubmit={handleFormSubmit}
        onBack={handleGoBack}
      />
    </ApplicationLayout>
  )
}
